












































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import stacksList from './stacksList.vue'

@Component({
  name: 'LinkList',
  components: {
    stacksList,
  },
})
export default class LinkList extends VueBase {
  @Prop() tree: any
  @Prop() textTopNumber: any

  @Watch('textTopNumber', { immediate: true, deep: true })
  onDataChanged() {
    // 修复建议距离顶部高度
    // console.log('textTopNumber', this.textTopNumber)
  }
  created() {
    this.tree.forEach((element: any) => {
      this.$set(element, 'open', false)
    })
  }
  private levelColor(level: any) {
    switch (level) {
      case '危险方法':
        return '#E56363'
      case '污点来源方法':
        return '#F49E0B'
      case '传播方法':
        return '#DBDBDB'
    }
  }

  private levelLabel(level: any) {
    switch (level) {
      case 2:
        return '危险函数执行'
      case 0:
        return '外部参数传入'
      case 1:
        return '数据调用传播'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
