var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"links"},_vm._l((_vm.tree),function(vItem,vIndex){return _c('div',{key:vIndex,staticClass:"v-box",class:[
      vIndex === _vm.tree.length - 1 ? 'last-no-border' : '',
      _vm.tree.length === 1 ? 'only-one' : '' ]},[_c('div',{staticClass:"v-title-line"}),_c('div',{staticClass:"v-title-top-line"}),_c('div',{staticClass:"v-title-bottom-line"}),_c('div',{staticClass:"v-title",on:{"click":function($event){vItem.open = !vItem.open}}},[_c('div',{staticClass:"v-title-before",style:({ background: _vm.levelColor(vItem.type) })}),_c('div',{staticClass:"v-title-dot",style:({ background: _vm.levelColor(vItem.type) })}),_c('div',{staticClass:"left"},[_vm._v(_vm._s(vItem.type))]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"center-left"},[(vItem.uuid)?[_c('div',[_vm._v(" 【"+_vm._s(vItem.metav2.type)+"】 "+_vm._s(vItem.metav2.project_name)+" @"+_vm._s(vItem.metav2.project_version)+" ")]),_c('div',[_vm._v(" "+_vm._s(vItem.callerClass + ':' + vItem.callerLineNumber)+" ")])]:[_c('div',[_vm._v(" "+_vm._s(vIndex == _vm.tree.length - 1 ? '数据对象返回' : '数据对象传播')+" ")]),_c('div',{attrs:{"title":vItem.callerClass + ':' + vItem.callerLineNumber}},[_vm._v(" "+_vm._s(vItem.callerClass + ':' + vItem.callerLineNumber)+" ")])]],2),_c('div',{staticClass:"center-right"},[_c('span',{attrs:{"title":vItem.type === '危险方法'
                ? vItem.sourceValues
                : vItem.targetValues}},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                vItem.type === '危险方法'
                  ? vItem.sourceValues
                  : vItem.targetValues
              ),expression:"\n                vItem.type === '危险方法'\n                  ? vItem.sourceValues\n                  : vItem.targetValues\n              "}]})])])]),_c('i',{staticClass:"icon iconfont rightFont",class:vItem.open ? 'active' : ''},[_vm._v("")])]),_c('div',[(vItem.open)?_c('div',[(vItem.graphv2 && vItem.graphv2.length)?_c('stacksList',{staticClass:"link-card",attrs:{"tree":vItem.graphv2}}):_c('div',[_c('div',{staticClass:"info-card"},[_c('div',{staticClass:"info-card-title"},[_vm._v(_vm._s(_vm.levelLabel(vItem.tag)))]),_c('div',{staticClass:"info-card-item"},[_c('div',{staticClass:"info-card-item-label"},[_vm._v("方法")]),_c('div',{staticClass:"info-card-item-value"},[_vm._v(_vm._s(vItem.methodName))])]),_c('div',{staticClass:"info-card-item"},[_c('div',{staticClass:"info-card-item-label"},[_vm._v("对象")]),_c('div',{staticClass:"info-card-item-value"},[_vm._v(_vm._s(vItem.className))])]),_c('div',{staticClass:"info-card-item"},[_c('div',{staticClass:"info-card-item-label"},[_vm._v("污点输入")]),_c('div',{staticClass:"info-card-item-value"},[_vm._v(" "+_vm._s(vItem.ori_sourceValues)+" ")])]),_c('div',{staticClass:"info-card-item"},[_c('div',{staticClass:"info-card-item-label"},[_vm._v("污点输出")]),_c('div',{staticClass:"info-card-item-value"},[_vm._v(" "+_vm._s(vItem.ori_targetValues)+" ")])])])])],1):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }